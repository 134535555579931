import { useForm } from 'react-hook-form'
import { useNavigate } from 'react-router-dom'
import { EyeIcon } from '@heroicons/react/outline'
import { usePagination } from 'hooks/usePagination'
import { yupResolver } from '@hookform/resolvers/yup'
import { Button, Spinner, Tooltip } from '@nextui-org/react'
import { dateRangeSchema } from 'validations/dateRangeSchema'
import React, { useMemo, useCallback, useState } from 'react'
import { ArrowLineDown, Download } from '@phosphor-icons/react'
import { getAllClaimSubmissions } from 'services/partnerService'
import { InformationModal } from 'components/generics/info-modal'
import { formatDataDatesByList, formatDataForTable } from 'helpers'
import { DateRangeFilterForm } from '../customers-info-tab/DateRangeFilterForm'
import { formatDateRange } from '../customers-info-tab/customer-info-tab-helpers'
import { FilterDropdown } from 'components/generics/filter-dropdown/FilterDropDown'
import { SearchBarComponent } from 'components/generics/search-bar/SearchBarComponent'
import { PaginationComponent } from 'components/generics/pagination/PaginationComponent'
import { MultiSelectTableDropdown } from 'components/generics/multi-select-table-dropdown'
import { DefaultTableComponent } from 'components/generics/default-table/DefaultTableComponent'
import {
  getClaimDataByIds,
  requestAllClaimSubmissionDownload,
} from 'services/partnerService'

import {
  handleAllDataDownload,
  handleCurrentDataDownload,
} from 'helpers/handle-downloads'
import {
  claimsTabModalActions,
  defaultColumnsToDisplay,
  allColumns,
  dateFields,
  filterSubmissionOptions,
  requestSubmissionModalText,
  claimsWarningModalActions,
  requestSubmissionModalWarning,
} from './claim-submission-tab-constants'

export const ClaimSubmissionsTab = () => {
  const navigate = useNavigate()
  const [searchValue, setSearchValue] = useState('')
  const [selectedFilter, setSelectedFilter] = useState(null)
  const [columnsToDisplay, setColumnsToDisplay] = useState(
    defaultColumnsToDisplay,
  )

  const {
    data,
    totalPages,
    currentPage,
    isLoading,
    handlePageChange,
    handleSearch,
    handleFilter,
    handleDateRange,
  } = usePagination(getAllClaimSubmissions)

  const {
    control,
    handleSubmit,
    setValue,
    watch,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(dateRangeSchema),
    defaultValues: { dateRange: [null, null] },
  })

  const tableData = useMemo(() => {
    const formattedData = formatDataDatesByList(data, dateFields)
    return formatDataForTable(formattedData, columnsToDisplay)
  }, [data, columnsToDisplay])

  const [isModalOpen, setIsModalOpen] = useState(false)
  const [isAttentionOpen, setIsAttentionOpen] = useState(false)
  const [isDataDownloading, setIsDataDownloading] = useState(false)

  const renderActions = useCallback(
    form => (
      <div className="flex gap-3 justify-center">
        <Tooltip content="View">
          <span className="cursor-pointer">
            <a
              href={`/view-claim/${form?.id}`}
              target="_blank"
              rel="noopener noreferrer"
            >
              <EyeIcon className="h-4 w-4" />
            </a>
          </span>
        </Tooltip>
      </div>
    ),
    [],
  )

  const onSearchInputChange = value => setSearchValue(value)

  const onSearchExecute = (refetch = false) => {
    refetch ? handleSearch('') : handleSearch(searchValue)
  }

  const handleFilterChange = selectedOption => {
    setSelectedFilter(
      selectedOption?.value === selectedFilter ? '' : selectedOption?.value,
    )
    handleFilter(selectedOption?.value || '')
  }

  const handleColumnSelectionChange = selectedColumns => {
    const columnsWithoutActions = selectedColumns.filter(
      col => col !== 'actions',
    )
    setColumnsToDisplay([...columnsWithoutActions, 'actions'])
  }

  const handleDownloadInitiate = () => {
    setIsAttentionOpen(false)
    handleAllDataDownload(
      setIsDataDownloading,
      selectedFilter,
      setIsModalOpen,
      requestAllClaimSubmissionDownload,
    )
  }
  const handleGoToReportDownload = () => {
    navigate('/partner/dashboard/reports-download')
  }

  const onDateRangeSubmit = data => {
    const { date_start, date_end } = formatDateRange(data.dateRange)
    handleDateRange(date_start, date_end)
  }

  const onDateRangeCancel = () => {
    setValue('dateRange', [null, null])
    handleDateRange(null, null)
  }

  return (
    <>
      {isLoading ? (
        <div className="flex justify-center py-24">
          <Spinner color="default" size="lg" />
        </div>
      ) : (
        <div className="flex flex-col gap-4">
          <div className="flex justify-end w-full gap-4">
            <Button
              className="ml-4 py-3 min-w-48 items-center hover:bg-gold-100 text-black text-xs"
              radius="sm"
              variant="bordered"
              color="solid"
              startContent={<ArrowLineDown />}
              isDisabled={isDataDownloading}
              onClick={() =>
                handleCurrentDataDownload(
                  tableData,
                  setIsDataDownloading,
                  getClaimDataByIds,
                  'ClaimForm_Submission_Data.csv',
                )
              }
            >
              Download Current Records
            </Button>
            <Button
              className="py-3 min-w-40 items-center text-xs bg-gray-950 text-white"
              radius="sm"
              variant="solid"
              startContent={<Download />}
              isDisabled={isDataDownloading}
              onClick={() => setIsAttentionOpen(true)}
            >
              Request All Records
            </Button>
          </div>

          <div className="flex flex-col gap-3">
            <div className="flex items-center">
              <div className="flex flex-col gap-4 w-full">
                <div className="basis-8/12">
                  <h2 className="text-2xl text-gray-600">Claim Submissions</h2>
                </div>
                <div className="flex justify-end gap-3 items-center">
                  <div className="basis-1/6">
                    <MultiSelectTableDropdown
                      allOptions={allColumns}
                      defaultSelectedOptions={defaultColumnsToDisplay}
                      onSelectionChange={handleColumnSelectionChange}
                    />
                  </div>

                  <div className="basis-1/6">
                    <FilterDropdown
                      options={filterSubmissionOptions}
                      value={selectedFilter}
                      onChange={handleFilterChange}
                    />
                  </div>
                  <div className="basis-1/6">
                    <DateRangeFilterForm
                      control={control}
                      errors={errors}
                      handleSubmit={handleSubmit}
                      onDateRangeSubmit={onDateRangeSubmit}
                      onDateRangeCancel={onDateRangeCancel}
                      watch={watch}
                    />
                  </div>
                  <div className="basis-1/6">
                    <SearchBarComponent
                      value={searchValue}
                      onChange={onSearchInputChange}
                      onSearch={onSearchExecute}
                      inputWrapperClass="border-2 border-gray-500 rounded-lg"
                    />
                  </div>
                </div>
              </div>
            </div>

            <DefaultTableComponent
              columns={tableData.columns}
              data={tableData.data}
              renderActions={renderActions}
              isRowPopUpEnabled={true}
              columnsExclude={['ID']}
            />
          </div>
        </div>
      )}

      <PaginationComponent
        total={totalPages}
        page={currentPage}
        onChange={handlePageChange}
        className="p-4 mt-3 flex flex-col items-end gap-x-2"
      />

      <InformationModal
        isOpen={isAttentionOpen}
        onClose={() => setIsAttentionOpen(false)}
        title={requestSubmissionModalWarning.title}
        body={<p>{requestSubmissionModalWarning.body}</p>}
        footerActions={claimsWarningModalActions(handleDownloadInitiate)}
      />

      <InformationModal
        isOpen={isModalOpen}
        onClose={() => setIsModalOpen(false)}
        title={requestSubmissionModalText.title}
        body={<p>{requestSubmissionModalText.body}</p>}
        footerActions={claimsTabModalActions(handleGoToReportDownload)}
      />
    </>
  )
}
