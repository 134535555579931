import React from 'react'
import {
  Table,
  TableHeader,
  TableColumn,
  TableBody,
  TableRow,
  TableCell,
} from '@nextui-org/react'

export const DefaultTableComponent = ({
  columns = [],
  data = [],
  renderActions,
  columnsExclude = [],
  isRowPopUpEnabled = false,
}) => {
  const renderCell = (item, columnKey) => {
    let cellValue = item[columnKey]

    if (typeof cellValue === 'object' && cellValue !== null) {
      cellValue = Object.keys(cellValue).join(', ')
    }

    switch (columnKey) {
      case 'actions':
        return renderActions ? renderActions(item) : null
      default:
        return cellValue !== null && cellValue !== undefined ? cellValue : 'N/A'
    }
  }

  const filteredColumns = columns.filter(
    column => !columnsExclude.includes(column.name),
  )

  const rowPopUpClasses = isRowPopUpEnabled
    ? 'group hover:bg-gradient-to-r hover:from-gray-100 hover:to-yellow-50 transition-all duration-300 cursor-pointer transform hover:scale-105 hover:shadow-2xl hover:z-10'
    : 'group hover:bg-indigo-50 transition-all duration-300 cursor-pointer'

  return (
    <Table
      aria-label="Enhanced Table"
      className="w-full table-auto rounded-lg overflow-hidden shadow-lg border border-gray-100 transition-all duration-300"
    >
      <TableHeader className="bg-gradient-to-r from-indigo-600 via-purple-600 to-pink-600 text-white">
        {filteredColumns.map(column => (
          <TableColumn
            key={column.uid}
            align={column.uid === 'actions' ? 'center' : 'start'}
            className="text-sm font-semibold text-left px-6 py-4 uppercase tracking-wider whitespace-nowrap"
          >
            {column.name}
          </TableColumn>
        ))}
      </TableHeader>

      <TableBody>
        {data.map((item, index) => (
          <TableRow key={item.id || index} className={rowPopUpClasses}>
            {filteredColumns.map(column => (
              <TableCell
                key={column.uid}
                className={`text-sm text-gray-800 py-4 px-6 border-b border-gray-200 transition-colors duration-300 relative ${
                  !isRowPopUpEnabled
                    ? 'group-hover:text-indigo-600'
                    : 'group-hover:text-gray-600'
                }`}
              >
                <span className="block">{renderCell(item, column.uid)}</span>
              </TableCell>
            ))}
          </TableRow>
        ))}
      </TableBody>
    </Table>
  )
}
