import React from 'react'

export const TestingEnvBanner = () => {
  const isTestingEnv = process.env.REACT_APP_CLIENT_ENV !== 'production'

  if (!isTestingEnv) return null

  return (
    <div className="top-0 left-0 w-full bg-red-600 text-white text-center py-0.5 text-base h-6 font-thin z-50">
      This is a testing environment
    </div>
  )
}
