import React, { useState } from 'react'
import { Link } from 'react-router-dom'
import { useLocation } from 'react-router-dom'
import { menuItems } from './app-sidebar-constants'
import { Tooltip } from '@nextui-org/tooltip'
import { CaretLineLeft, CaretLineRight } from '@phosphor-icons/react'
import { Button } from '@nextui-org/react'

export const PartnerSideBar = () => {
  const { pathname } = useLocation()
  const [isSidebarCollapsed, setIsSidebarCollapsed] = useState(false)

  const toggleSidebar = () => setIsSidebarCollapsed(!isSidebarCollapsed)

  return (
    <div className="flex">
      <div
        className={`transition-all duration-300 ease-in-out ${isSidebarCollapsed ? 'w-20' : 'w-52 md:w-64'} 
        bg-white shadow-md rounded-xl min-h-screen p-4 relative`}
      >
        <div className="mt-4 w-full">
          <ul className="gap-y-3 flex flex-col">
            {menuItems.map(({ to, icon, label }) => (
              <Link key={to} to={to}>
                <li
                  className={`flex justify-between items-center p-2 rounded-lg ${
                    pathname === to
                      ? 'bg-blue-100 cursor-default'
                      : 'cursor-pointer'
                  } ${isSidebarCollapsed ? 'mr-3' : 'mr-2'}`}
                >
                  <span className="flex items-center space-x-2">
                    <Tooltip content={label}>{icon}</Tooltip>
                    {!isSidebarCollapsed && (
                      <span className="whitespace-nowrap">{label}</span>
                    )}
                  </span>
                </li>
              </Link>
            ))}
          </ul>
        </div>

        <Tooltip
          content={isSidebarCollapsed ? 'Expand Sidebar' : 'Collapse Sidebar'}
          placement="right"
        >
          <div className="absolute top-0 right-0 h-full cursor-pointer bg-gray-100 w-2 rounded-sm flex items-center justify-center align-middle">
            <Button
              className="bg-transparent border-none hover:bg-transparent focus:bg-transparent active:bg-transparent"
              onPress={toggleSidebar}
            >
              {isSidebarCollapsed ? (
                <CaretLineRight className="w-4 h-5 text-black" />
              ) : (
                <CaretLineLeft className="w-4 h-5 text-black" />
              )}
            </Button>
          </div>
        </Tooltip>
      </div>
    </div>
  )
}
