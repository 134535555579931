import React from 'react'
import { Users, FolderUser, ListPlus, FileCsv } from '@phosphor-icons/react'

export const menuItems = [
  {
    to: '/partner/dashboard',
    icon: <Users className="w-5 h-5 text-black" />,
    label: 'Customers',
  },
  {
    to: '/partner/dashboard/claim-submissions',
    icon: <ListPlus className="w-5 h-5 text-black" />,
    label: 'Claims',
  },
  {
    to: '/partner/dashboard/reports-download',
    icon: <FileCsv className="w-5 h-5 text-black" />,
    label: 'Reports Download',
  },
  {
    to: '/partner/dashboard/claim-forms',
    icon: <FolderUser className="w-5 h-5 text-black" />,
    label: 'Claim Form and URL',
  },
  {
    to: '/partner/dashboard/monthly-reports',
    icon: <FileCsv className="w-5 h-5 text-black" />,
    label: 'Monthly Reports',
  },
]
